<template>
  <div class="container-page container-page-rktv">
    <RestrictionMeasureTable />
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';

  import RestrictionMeasureTable from '../components/restrictionMeasure/RestrictionMeasureTable.vue';

  export default {
    name: 'RestrictionMeasuresPage',
    components: { RestrictionMeasureTable },
    mixins: [security],
    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.RestrictionMeasureAccess);
    },
  };
</script>
