<template>
  <p class="fira-sans paragraph" :class="{ 'minimized-text': isMinimizingActive && isMinimized }">
    {{ text }}
  </p>
  <ButtonComponent
    v-if="isMinimizingActive"
    class="cell-button"
    btnType="rktvBlueGreen"
    @click.stop="toggleMinimized"
    :title="buttonTitle"
  />
</template>

<script>
  import ButtonComponent from '@/components/buttons/ButtonComponent.vue';

  export default {
    name: 'MinimizedTableCell',
    components: {
      ButtonComponent,
    },
    props: {
      text: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isMinimized: true,
      };
    },
    methods: {
      toggleMinimized() {
        this.isMinimized = !this.isMinimized;
      },
    },
    computed: {
      isMinimizingActive() {
        return this.text.length > 100;
      },
      buttonTitle() {
        return this.isMinimized ? 'Читать полностью' : 'Свернуть';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/global/mixin.scss';

  .paragraph {
    white-space: pre-wrap;
  }
  .minimized-text {
    @include line-clamp(5, 18px);
  }
  .cell-button {
    font-size: 12px;
    margin-top: 6px !important;
    padding: 8px 12px;
  }
</style>
