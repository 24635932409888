<template>
  <div class="title-container">
    <p class="title-inner">
      Реестр ограничительных мер, применяемых третьими странами в отношении российских товаров, услуг и инвестиций
    </p>
    <div v-if="!readonly" class="button-container">
      <ButtonStock
        title="Создать"
        mode="iconRight"
        iconName="plus_white"
        btnType="rktvBlueGreen"
        @click="editCard(0)"
      />
      <ButtonStock
        :disabled="importInProgress"
        :title="!importInProgress ? 'Загрузить из Excel' : 'Загрузка...'"
        btnType="rktvBlueGreen"
        iconName="import"
        mode="iconRight"
        @click="importExcel()"
      />
    </div>
  </div>

  <div
    class="filter-trigger"
    :class="{
      active: openAdditional,
      'filter-have-value': filterNotEmpty,
    }"
    @click="openAdditional = !openAdditional"
  >
    <p class="filter-text">Фильтр</p>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M20 5H4L10.4 12.3578V17.4444L13.6 19V12.3578L20 5Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>

  <div v-if="openAdditional" class="list-restriction-measures__filter-container">
    <FiltersComponent :filter-props="filter">
      <div class="list-restriction-measures__filter-buttons">
        <ButtonStock class="btn--average" @click="successFilter">Применить</ButtonStock>
        <ButtonStock title="Сбросить" btnType="blueColorNoBorder" @click="clearFilter" />
      </div>
    </FiltersComponent>
  </div>

  <RestrictionMeasureTableComponent
    :request="request"
    :restrictionItems="page"
    :isLoading="isLoading"
    :isLoadingMore="isLoadingMore"
    :readonly="readonly"
    :countryId="countryId"
    @changeSorting="changeSorting"
    @editCard="editCard"
    @deleteCard="deleteCard"
    @onPageChange="onPageChange"
  />

  <section>
    <ModalComponent
      content-class="modal--new"
      v-model="importModal"
      headType="redesigned"
      title="Импорт реестра"
      @close="closeModal"
    >
      <a href="javascript:" @click="getTemplate" class="restriction-measure__template-file fira-sans">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M9.33334 1.3335H4.00001C3.64638 1.3335 3.30724 1.47397 3.0572 1.72402C2.80715 1.97407 2.66667 2.31321 2.66667 2.66683V13.3335C2.66667 13.6871 2.80715 14.0263 3.0572 14.2763C3.30724 14.5264 3.64638 14.6668 4.00001 14.6668H12C12.3536 14.6668 12.6928 14.5264 12.9428 14.2763C13.1929 14.0263 13.3333 13.6871 13.3333 13.3335V5.3335L9.33334 1.3335Z"
            stroke="#0000EE"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M9.33333 1.3335V5.3335H13.3333" stroke="#0000EE" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.6667 8.6665H5.33333" stroke="#0000EE" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.6667 11.3335H5.33333" stroke="#0000EE" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Скачать шаблон</a
      >
      <ImportModalRedesigned v-if="importModal" @doimport="doImport" @close="closeModal" />
    </ModalComponent>
  </section>

  <ModalInfo
    v-model="isOpenModalInfo"
    :titleModal="titleModal"
    :textModal="textModal"
    :textModalPreWrap="textModalPreWrap"
    :modalIconName="modalIconName"
    @closeModal="closeModalInfo"
  />
</template>

<script>
  import ImportModalRedesigned from '@/common/components/redesigned/ImportModalRedesigned.vue';
  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import modalInfo from '@/common/mixins/modalInfo';

  import ButtonStock from '@/components/buttons/ButtonStock';
  import ModalComponent from '@/components/modals/ModalComponent';

  import Api from '../../api/restrictmeasures';
  import { DEFAULT_FILTER_VALUES, MODAL_INFO_MESSAGES } from '../../utils/consts';
  import FiltersComponent from '../FiltersComponent.vue';
  import RestrictionMeasureTableComponent from './RestrictionMeasureTableComponent.vue';

  export default {
    name: 'RestrictionMeasureTable',
    components: {
      ButtonStock,
      ModalComponent,
      RestrictionMeasureTableComponent,
      ImportModalRedesigned,
      FiltersComponent,
      ModalInfo,
    },
    props: {
      readonly: {
        type: Boolean,
        default: false,
      },
    },
    mixins: [filtermanager, modalInfo],
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          //  PublicSortColumn:
          // 0 - сортировка по стране
          // 1 - наименование
          // 2 - соритровка по коду
          // 3 - вид барьера
          // 4 - по дате окончания
          // 5 - по описанию барьера
          // 6 - дата создания
          // 7 - Мера защиты рынка
          // 8 - сортировка по виду предмета торговли
          // 9 - сортировка по отрасли
          // 10 - сортировка по категории барьера
          // 11 - барьер активен или нет
          // 12 - дата выявления
          // 13 - барьер либерализован
          // 14 - дата либерализации
          // 15 - год последней либерализации
          // 16 - сортировка по сумме ущерба (предотвращенного)
          // 17 - сортировка по сумме ущерба (предполагаемого)
          // 18 - дата устранения
          // 19 - орган ответственный за ведение меры
          // 20 - ответственный от МЭР
          // 21 - Дата введения
          // 22 - дата начала пересмотра меры
          // 23 - размер пошлины
          // 24 - нормативные документы, вводящие меру
          // 25 - заинтересованные юридические лица
          // 26 - Дата последнего изменения
          publicSortColumn: 0,
          // publicSortOrder:
          // 0 - по возрастанию
          // 1 - по убыванию
          publicSortOrder: 0,
        },
        filter: [],
        filterNotEmpty: false,
        isLoading: false,
        isLoadingMore: false,

        openAdditional: false,
        importModal: false,
        importInProgress: false,
      };
    },
    mounted() {
      this.applyQueryParams();
      this.changeFilter();
    },
    methods: {
      applyQueryParams() {
        const {
          isActive,
          isBarrierActive,
          tradeItemTypeId,
          barrierIdentificationTo,
          barrierIdentificationFrom,
          barrierRemovalTo,
          barrierRemovalFrom,
          createdFrom,
          countryId,
        } = this.$route.query;

        this.filter = { ...DEFAULT_FILTER_VALUES };

        if (isBarrierActive === 'true') this.filter.isBarrierActive = true;
        if (isBarrierActive === 'false') this.filter.isBarrierActive = false;

        if (countryId) {
          this.filter.countryIds = Array.isArray(countryId) ? countryId : [countryId];
        }

        this.filter.tradeItemTypeId = tradeItemTypeId;
        this.filter.barrierIdentificationTo = barrierIdentificationTo;
        this.filter.barrierIdentificationFrom = barrierIdentificationFrom;
        this.filter.barrierRemovalTo = barrierRemovalTo;
        this.filter.barrierRemovalFrom = barrierRemovalFrom;
        this.filter.createdFrom = createdFrom;
        this.filter.isActive = isActive;
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.filterNotEmpty = this.isFilterNotEmpty(this.filter, ['pageNumber', 'pageSize', 'isActive']);
        this.loadPage(false, true);
      },
      successFilter() {
        this.changeFilter();
        this.openAdditional = false;
      },
      clearFilter() {
        this.filter.countryGroupIds = null;
        this.filter.tradeItemTypeId = null;
        this.filter.industryIds = [];
        this.filter.tnvedIndustryIds = [];
        this.filter.countryIds = [];
        this.filter.aggregateIndustryIds = [];
        this.filter.vedName = '';
        this.filter.vedCode = '';
        this.filter.barrierCategoryIds = [];
        this.filter.measureKindIds = [];
        this.filter.defenceMeasureIds = [];
        this.filter.isBarrierActive = null;
        this.filter.isBarrierLiberalized = null;
        this.filter.responsibleOrgIds = [];
        this.filter.description = '';
        this.filter.preventedDamageAmountFrom = null;
        this.filter.preventedDamageAmountTo = null;
        this.filter.allegedDamageAmountFrom = null;
        this.filter.allegedDamageAmountTo = null;
        this.filter.nomenclatureName = '';
        this.filter.codes = '';
        this.filter.lastLiberalizationYearFrom = null;
        this.filter.lastLiberalizationYearTo = null;
        this.filter.revisionStartDateFrom = null;
        this.filter.revisionStartDateTo = null;
        this.filter.beginDateFrom = null;
        this.filter.beginDateTo = null;
        this.filter.endDateFrom = null;
        this.filter.endDateTo = null;
        this.filter.normativeDocument = '';
        this.filter.feeAmount = null;
        this.filter.responsibleEmployeeIds = [];
        this.filter.interestedLegalPersons = '';
        this.filter.note = '';
        this.filter.actualizedFrom = null;
        this.filter.actualizedTo = null;
        this.filter.isActive = null;
        this.filter.createdFrom = null;
        this.filter.createdTo = null;
        this.filter.modifiedFrom = null;
        this.filter.modifiedTo = null;
        this.filter.statusId = null;
        this.filter.liberalizationDateFrom = null;
        this.filter.liberalizationDateTo = null;
        this.filter.barrierIdentificationFrom = null;
        this.filter.barrierIdentificationTo = null;
        this.filter.barrierRemovalFrom = null;
        this.filter.barrierRemovalTo = null;

        this.$router.replace({ query: {} });
        this.saveFilter(this.filter);
        this.changeFilter();
      },
      preprocessFilter() {
        this.filter.industryIds = [];
        this.filter.tnvedIndustryIds = [];
        console.log(this.filter.aggregateIndustryIds);
        this.filter.aggregateIndustryIds.forEach((item) => {
          if (item) {
            let valueArr = item.split(';');
            if (valueArr[1] === String(Constants.tradeItemType.tnved))
              this.filter.tnvedIndustryIds.push(Number(valueArr[0]));
            else if (valueArr[1] === String(Constants.tradeItemType.okved))
              this.filter.industryIds.push(Number(valueArr[0]));
          }
        });
      },
      loadPage(append, useCache) {
        this.preprocessFilter();
        var params = Object.assign(this.filter, this.request);
        this.isLoading = !append;
        if (append) {
          this.isLoadingMore = true;
        }
        Api.search(params, useCache)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.isLoading = false;
            this.isLoadingMore = false;
          });
      },
      editCard(id) {
        this.$router.push({
          name: 'RestrictionMeasureEditPage',
          params: { id: id, action: 'edit' },
        });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            Api.delete(id)
              .then(() => {
                this.isOpenModalInfo = true;
                this.titleModal = 'Удаление';
                this.textModal = MODAL_INFO_MESSAGES.REMOVE_SUCCESS;
                this.modalIconName = 'acceptIcon';

                this.$nextTick(() => {
                  this.loadPage(false, false);
                });
              })
              .catch((error) => {
                console.error(error);
                this.isOpenModalInfo = true;
                this.titleModal = 'Удаление';
                this.textModal =
                  error.response && error.response.data ? error.response.data : MODAL_INFO_MESSAGES.REMOVE_ERROR;
                this.modalIconName = 'errorIcon';
              });
          }
        });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist, true);
      },
      changeSorting(id) {
        if (this.request.publicSortColumn == id)
          this.request.publicSortOrder = this.request.publicSortOrder == 0 ? 1 : 0;
        else this.request.publicSortOrder = 0;
        this.request.publicSortColumn = id;
        this.loadPage(false);
      },
      importExcel() {
        this.importModal = true;
      },
      doImport(file) {
        this.closeModal();
        this.importInProgress = true;
        Api.uploadFile(file)
          .then(() => {
            this.isOpenModalInfo = true;
            this.titleModal = 'Импорт';
            this.textModal = MODAL_INFO_MESSAGES.IMPORT_SUCCESS;
            this.modalIconName = 'acceptIcon';
            this.importInProgress = false;
            this.loadPage();
            this.importInProgress = false;
          })
          .catch((error) => {
            let txt = MODAL_INFO_MESSAGES.IMPORT_ERROR;
            if (error?.response?.data && error?.response?.data.startsWith('Ошибки при разборе файла'))
              txt = error?.response?.data;
            this.isOpenModalInfo = true;
            this.titleModal = 'Ошибка';
            this.textModalPreWrap = txt;
            this.modalIconName = 'errorIcon';
            this.importInProgress = false;
          });
      },
      getTemplate() {
        Api.getTemplate();
      },
    },
    watch: {
      isBarrierActive: function () {
        this.changeFilter();
      },
      isBarrierLiberalized: function () {
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../styles/restrictionMeasureTable.scss';
</style>
