<template>
  <div>
    <div class="position-relative">
      <div class="table-container-new arrow-nav mb-16">
        <table class="stockTable">
          <thead>
            <th class="first-column-fixed">
              <p class="th-total">Всего: {{ restrictionItems.itemsTotal }} №</p>
            </th>

            <th class="second-column-fixed" @click="changeSorting(0)">
              <p class="th-country cursor-pointer" :class="getClassSorting(0)">Страна</p>
            </th>

            <th class="third-column-fixed" @click="changeSorting(1)">
              <p class="th-name cursor-pointer" :class="getClassSorting(1)">Наименование</p>
            </th>

            <th @click="changeSorting(8)">
              <p class="th-trade-type cursor-pointer" :class="getClassSorting(8)">Вид предмета торговли</p>
            </th>

            <th @click="changeSorting(2)">
              <p class="th-code cursor-pointer" :class="getClassSorting(2)">Код</p>
            </th>

            <th @click="changeSorting(9)">
              <p class="th-industry cursor-pointer" :class="getClassSorting(9)">Отрасль</p>
            </th>

            <th @click="changeSorting(10)">
              <p class="th-barrier-category cursor-pointer" :class="getClassSorting(10)">Категория барьера</p>
            </th>

            <th @click="changeSorting(3)">
              <p class="th-barrier-type cursor-pointer" :class="getClassSorting(3)">Вид барьера</p>
            </th>

            <th @click="changeSorting(7)">
              <p class="th-measure-protection cursor-pointer" :class="getClassSorting(7)">Мера защиты рынка</p>
            </th>

            <th @click="changeSorting(11)">
              <p class="th-measure-active cursor-pointer" :class="getClassSorting(11)">Барьер активен</p>
            </th>

            <th @click="changeSorting(12)">
              <p class="th-identification-date cursor-pointer" :class="getClassSorting(12)">Дата выявления</p>
            </th>

            <th @click="changeSorting(13)">
              <p class="th-measure-liberalized cursor-pointer" :class="getClassSorting(13)">Барьер либерализован</p>
            </th>

            <th @click="changeSorting(14)">
              <p class="th-measure-liberalized cursor-pointer" :class="getClassSorting(14)">Дата либерализации</p>
            </th>

            <th @click="changeSorting(15)">
              <p class="th-liberalized-year cursor-pointer" :class="getClassSorting(15)">Год последней либерализации</p>
            </th>

            <th @click="changeSorting(16)">
              <p class="th-amount-prevented cursor-pointer" :class="getClassSorting(16)">
                Сумма предотвращенного ущерба, млн $/год
              </p>
            </th>

            <th @click="changeSorting(17)">
              <p class="th-amount-expected cursor-pointer" :class="getClassSorting(17)">
                Сумма пред­полагаемого ущерба, млн $/год
              </p>
            </th>

            <th @click="changeSorting(18)">
              <p class="th-removal-date cursor-pointer" :class="getClassSorting(18)">Дата устранения</p>
            </th>

            <th @click="changeSorting(19)">
              <p class="th-responsible-body cursor-pointer" :class="getClassSorting(19)">
                Орган, ответственный за введение меры
              </p>
            </th>

            <th @click="changeSorting(20)">
              <p class="th-responsible-mer cursor-pointer" :class="getClassSorting(20)">Ответственный от МЭР</p>
            </th>

            <th @click="changeSorting(21)">
              <p class="th-begin-date cursor-pointer" :class="getClassSorting(21)">Дата введения</p>
            </th>

            <th @click="changeSorting(4)">
              <p class="th-end-date cursor-pointer" :class="getClassSorting(4)">Дата окончания</p>
            </th>

            <th @click="changeSorting(22)">
              <p class="th-revision-date cursor-pointer" :class="getClassSorting(22)">Дата нача­ла пере­смотра меры</p>
            </th>

            <th @click="changeSorting(23)">
              <p class="th-duty-amount cursor-pointer" :class="getClassSorting(23)">Размер пошлины</p>
            </th>

            <th @click="changeSorting(5)">
              <p class="th-description cursor-pointer" :class="getClassSorting(5)">Описание барьера</p>
            </th>

            <th>
              <p class="th-materials">Прилагаемые документы/Материалы расследований</p>
            </th>

            <th @click="changeSorting(24)">
              <p class="th-docs cursor-pointer" :class="getClassSorting(24)">Нормативный документ, вводящий меру</p>
            </th>

            <th @click="changeSorting(25)">
              <p class="th-legal-person cursor-pointer" :class="getClassSorting(25)">
                Заинтересованные юридические лица
              </p>
            </th>
            <th @click="changeSorting(6)">
              <p class="th-create-date cursor-pointer" :class="getClassSorting(6)">Дата создания ограничения</p>
            </th>
            <th @click="changeSorting(26)">
              <p class="th-edit-date cursor-pointer" :class="getClassSorting(26)">Дата изменения ограничения</p>
            </th>
          </thead>

          <tbody v-if="restrictionItems.items?.length && !isLoading">
            <tr v-for="(item, index) of restrictionItems.items" :key="item.id" @click="editCard(item.id)">
              <td class="td-icons first-column-fixed">
                <div>
                  <div>
                    <svg
                      v-show="!readonly"
                      @click.stop="editCard(item.id)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      class="editIcon"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_5804_11650)">
                        <path
                          d="M7.33301 2.66663H2.66634C2.31272 2.66663 1.97358 2.8071 1.72353 3.05715C1.47348 3.3072 1.33301 3.64634 1.33301 3.99996V13.3333C1.33301 13.6869 1.47348 14.0261 1.72353 14.2761C1.97358 14.5262 2.31272 14.6666 2.66634 14.6666H11.9997C12.3533 14.6666 12.6924 14.5262 12.9425 14.2761C13.1925 14.0261 13.333 13.6869 13.333 13.3333V8.66663"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.333 1.66665C12.5982 1.40144 12.9579 1.25244 13.333 1.25244C13.7081 1.25244 14.0678 1.40144 14.333 1.66665C14.5982 1.93187 14.7472 2.29158 14.7472 2.66665C14.7472 3.04173 14.5982 3.40144 14.333 3.66665L7.99967 9.99999L5.33301 10.6667L5.99967 7.99999L12.333 1.66665Z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5804_11650">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <svg
                      v-show="!readonly"
                      class="removeIcon"
                      @click.stop="deleteCard(item.id)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path d="M2 4H3.33333H14" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M12.6663 4.00004V13.3334C12.6663 13.687 12.5259 14.0261 12.2758 14.2762C12.0258 14.5262 11.6866 14.6667 11.333 14.6667H4.66634C4.31272 14.6667 3.97358 14.5262 3.72353 14.2762C3.47348 14.0261 3.33301 13.687 3.33301 13.3334V4.00004M5.33301 4.00004V2.66671C5.33301 2.31309 5.47348 1.97395 5.72353 1.7239C5.97358 1.47385 6.31272 1.33337 6.66634 1.33337H9.33301C9.68663 1.33337 10.0258 1.47385 10.2758 1.7239C10.5259 1.97395 10.6663 2.31309 10.6663 2.66671V4.00004"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path d="M6.66699 7.33337V11.3334" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9.33301 7.33337V11.3334" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                  {{ restrictionItems.firstItemNumber + index }}
                </div>
              </td>
              <td class="second-column-fixed">
                {{ getCountry(item) }}
              </td>
              <td class="third-column-fixed">
                <MinimizedTableCell :text="getName(item)" />
              </td>
              <td>{{ item.tradeItemType.name }}</td>
              <td>{{ getCode(item) }}</td>
              <td v-if="item.tradeItemTypeId === tradeItemType.tnved">
                {{ item.tnvedIndustry?.name }}
              </td>
              <td v-else-if="item.tradeItemTypeId === tradeItemType.okved">
                {{ item.industry?.name }}
              </td>
              <td>{{ item.barrierCategory?.name }}</td>
              <td>{{ item.measureKind?.name }}</td>
              <td>{{ item.defenceMeasure?.name }}</td>
              <td>{{ getIsBarrierActive(item) }}</td>
              <td>{{ getIsBarrierIdentificationDate(item) }}</td>
              <td>{{ getIsBarrierLiberalized(item) }}</td>
              <td>{{ getLiberalizationDate(item) }}</td>
              <td>{{ item.lastLiberalizationYear }}</td>
              <td>{{ item.preventedDamageAmount }}</td>
              <td>{{ item.allegedDamageAmount }}</td>
              <td>{{ getRemovalDate(item) }}</td>
              <td>{{ item.responsibleOrg?.name }}</td>
              <td>{{ item.responsibleEmployee?.fullName }}</td>
              <td>{{ getBeginDate(item) }}</td>
              <td>{{ getEndDate(item) }}</td>
              <td>{{ getRevisionDate(item) }}</td>
              <td>{{ item.feeAmount }}</td>
              <td>
                <MinimizedTableCell :text="item.description" />
              </td>
              <td>
                <p v-for="file in item.files" :key="file.originalName">
                  {{ file.originalName }}
                </p>
              </td>
              <td>{{ item.normativeDocument }}</td>
              <td>{{ item.interestedLegalPersons }}</td>

              <td>{{ getCreateDate(item) }}</td>
              <td>{{ getModifiedDate(item) }}</td>
            </tr>
          </tbody>

          <tbody v-else-if="!isLoading && restrictionItems.items?.length === 0">
            <tr>
              <td :colspan="emptyColSpan">Нет данных.</td>
            </tr>
          </tbody>
        </table>

        <Loader class="restriction-table-loading" v-if="isLoading" />
      </div>
      <div v-if="restrictionItems.pagesTotal > 1">
        <PaginationRedesigned
          :page-count="restrictionItems.pagesTotal"
          :current-page="restrictionItems.pageNumber"
          :loadingMore="isLoadingMore"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import Constants from '@/common/constants';

  import Loader from '@/components/Loader';

  import MinimizedTableCell from './MinimizedTableCell';

  export default {
    components: {
      MinimizedTableCell,
      PaginationRedesigned,
      Loader,
    },
    props: {
      request: {
        type: Object,
        required: true,
      },
      restrictionItems: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
      isLoadingMore: {
        type: Boolean,
        required: true,
      },

      readonly: {
        type: Boolean,
        default: false,
      },
      countryId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        tradeItemType: Constants.tradeItemType,
      };
    },
    methods: {
      getClassSorting(id) {
        if (this.request.publicSortColumn == id) {
          if (this.request.publicSortOrder == 0) return 'restriction-table-header_ascending';
          else if (this.request.publicSortOrder == 1) return 'restriction-table-header_descending';
        }
        return 'restriction-table-header_default';
      },
      changeSorting(id) {
        this.$emit('changeSorting', id);
      },
      editCard(id) {
        this.$emit('editCard', id);
      },
      deleteCard(id) {
        this.$emit('deleteCard', id);
      },
      onPageChange(pageNumber, addToExist) {
        this.$emit('onPageChange', pageNumber, addToExist);
      },
      dateFormat(date) {
        return this.$momentFormat(date, Constants.DATE_FORMATS['DD.MM.YYYY']);
      },
      getCountry(item) {
        return item.allCountries
          ? 'Все страны'
          : item.countryGroupName
            ? item.countryGroupName
            : item.countries?.map((x) => x.name).join('; ');
      },
      getName(item) {
        return item.allTradeItems
          ? 'Все товары'
          : item.name
            ? item.name
            : item[item.tradeItemTypeId === this.tradeItemType.tnved ? 'tnveds' : 'okveds']
                ?.map((x) => x.name)
                .join(`; `);
      },
      getCode(item) {
        return item.allTradeItems
          ? item.tradeItemTypeId === this.tradeItemType.tnved
            ? 'Все товары'
            : 'Все услуги'
          : item[item.tradeItemTypeId === this.tradeItemType.tnved ? 'tnveds' : 'okveds']
              ?.map((x) => x.code)
              .join(`; `);
      },
      getIsBarrierActive(item) {
        if (item.isBarrierActive === null) {
          return '';
        } else {
          return item.isBarrierActive ? 'да' : 'нет';
        }
      },
      getIsBarrierIdentificationDate(item) {
        return item.barrierIdentification ? this.dateFormat(item.barrierIdentification) : '';
      },
      getIsBarrierLiberalized(item) {
        if (item.getIsBarrierLiberalized === null) {
          return '';
        } else {
          return item.getIsBarrierLiberalized ? 'да' : 'нет';
        }
      },
      getLiberalizationDate(item) {
        return item.liberalizationDate ? this.dateFormat(item.liberalizationDate) : '';
      },
      getRemovalDate(item) {
        return item.barrierRemoval ? this.dateFormat(item.barrierRemoval) : '';
      },
      getRevisionDate(item) {
        return item.revisionStartDate ? this.dateFormat(item.revisionStartDate) : '';
      },
      getCreateDate(item) {
        return item.created ? this.dateFormat(item.created) : '';
      },
      getModifiedDate(item) {
        return item.modified ? this.dateFormat(item.modified) : '';
      },
      getBeginDate(item) {
        return item.beginDate ? this.dateFormat(item.beginDate) : '';
      },
      getEndDate(item) {
        return item.endDate ? this.dateFormat(item.endDate) : '';
      },
    },
    computed: {
      emptyColSpan() {
        let result = 24;
        if (this.countryId) result--;
        if (this.readonly) result -= 2;
        return result;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../styles/restrictionMeasureTableComponent.scss';
</style>
