<template>
  <div class="modal-container">
    <div class="form-group">
      <div class="control-label">Загрузка фала</div>

      <label class="input-file">
        <input ref="fileInput" type="file" name="file" id="file-input" :accept="accept" @change="fileChanged($event)" />
        <div class="input-file-container">
          <div class="input-file-btn btn-secondary">Выберите файл</div>
          <p class="input-file-text" type="text">{{ fileName }}</p>
        </div>
      </label>

      <div class="invalid-msg mt-3 fira-sans" v-if="v$.file.$error">Поле обязательно для заполнения</div>
    </div>
    <div class="buttons-container">
      <ButtonStock title="Загрузить" class="btn-update" @click="doImport" />
      <ButtonStock btn-type="blueColorNoBorder" title="Закрыть" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import ButtonStock from '@/components/buttons/ButtonStock';

  export default {
    name: 'ImportModalRedesigned',
    components: { ButtonStock },
    emits: ['doimport', 'close'],
    props: {
      acceptType: String,
    },
    data() {
      return {
        file: null,
        fileName: 'Файл не выбран',
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      };
    },
    created() {
      if (this.acceptType) this.accept = this.acceptType;
    },
    methods: {
      fileChanged(e) {
        if (e.target.files[0]) {
          this.file = e.target.files[0];
          this.fileName = e.target.files[0].name;
        }
      },
      doImport() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }
        this.$emit('doimport', this.file);
      },
      close() {
        this.fileName = 'Файл не выбран';
        this.file = null;
        const fileInput = this.$refs.fileInput;
        fileInput.value = '';

        fileInput.dispatchEvent(new Event('input'));
        this.$emit('close');
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        file: { required },
      };
    },
  };
</script>

<style scoped lang="scss">
  .modal-container {
    margin-top: 24px;
  }

  .control-label {
    font: $fira-20;
    font-weight: 500;
  }

  .input-file {
    position: relative;
    margin-top: 12px;
    width: 100%;
  }

  .input-file-container {
    display: flex;
    gap: 20px;

    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 16px;
    }
  }

  .input-file input[type='file'] {
    position: absolute;
    z-index: -1;
    left: 0;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }
  .input-file-btn {
    position: relative;
    text-wrap: nowrap;
    font-family: 'Fira Sans';
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;

    &:hover {
      opacity: 0.7;
    }
  }
  .input-file-text {
    align-self: center;
    text-align: left;
    float: left;
    box-sizing: border-box;
    width: 100%;
  }

  .buttons-container {
    display: flex;
    gap: 8px;
    margin-top: 32px;

    @media (max-width: $mobile) {
      flex-direction: column;
    }

    .btn-update {
      font: $fira-14;
    }
  }

  @media (max-width: $mobile) {
    .form-group {
      height: 298px;
    }
  }
</style>
